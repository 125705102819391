<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <h1>
            Анкеты
          </h1>
          <div class="list passport">
            <div v-for="(participant, participantIndex) in participants" :key="'participant' + participantIndex"
                 class="list-item-wrap">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="list-item">
                      <a class="list-item-name">
                        <button class="toggleSwitch">
                          <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                          </svg>
                          <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                          </svg>
                        </button>
                        <span>{{ participant.title }}</span>
                      </a>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    Ссылка для прохождения опроса:
                    <a :href="participant.anketlink" target="_blank"
                       style="text-decoration: none">{{ participant.anketlink }}</a>
                    <v-btn outlined @click="copyLink(participant.anketlink)" title="Скопировать в буфер обмена"
                           style="margin-left: 20px">
                      <v-icon class="icon-item icon-content-copy"></v-icon>
                    </v-btn>
                    <table>
                      <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td style="width: 300px;">
                          Количество {{ participant.title === 'Соискатели' ? 'соискателей' : 'работодателей' }},
                          прошедших опрос
                        </td>
                        <td>
                          {{ participant.anketsCount }}
                        </td>
                        <td>
                          <v-btn
                              class="btn btn-outline"
                              text
                              @click="navigateToAnkets(participant.anketId)">
                            Анкеты
                          </v-btn>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {getPeriodEndDate, getPeriodStartDate} from "@/modules/periods";
import {getAnketsCount} from "@/modules/ankets";
import { NEW_FRONTEND_URL } from '../../main'
export default {
  name: "ClientCentricityAnket",
  props: ['period'],
  watch: {
    $regionAndDepartmentInfo: {
      async handler() {
        await this.initialize()
      },
      deep: true
    },
    period: {
      async handler() {
        await this.initialize()
      }
    }
  },

  data() {
    return {
      apiLoaded: false,
      errorText: null,
    }
  },


  methods: {
    async initialize() {
      this.participants = [
        {
          title: 'Соискатели',
          anketId: 167679,
          anketlink: 'https://new.profczn.ru/' + 167679 + '?id=' + this.$regionAndDepartmentInfo.department.id,
          anketsCount: null
        },
        {
          title: 'Работодатели',
          anketId: 167682,
          anketlink: 'https://new.profczn.ru/' + 167682 + '?id=' + this.$regionAndDepartmentInfo.department.id,
          anketsCount: null
        }
      ]

      const [workerAnketsCount, employerAnketsCount] = await Promise.all([
        this.getAnketsCount(this.participants[0].anketId), // соискатели
        this.getAnketsCount(this.participants[1].anketId) // работодатели
      ])
      this.participants[0].anketsCount = workerAnketsCount
      this.participants[1].anketsCount = employerAnketsCount

      this.$forceUpdate()
    },

    copyLink(link) {
      navigator.clipboard.writeText(link);
    },

    navigateToAnkets(configId) {
      window.location.href = `${NEW_FRONTEND_URL}/ankets/register?configId=${configId}&beginDate=${getPeriodStartDate(this.period)}&endDate=${getPeriodEndDate(this.period)}&regionId=${this.$regionAndDepartmentInfo.region.code}&cznId=${this.$regionAndDepartmentInfo.department.id}`
    },

    async getAnketsCount(configId) {
      const anketQuerySpec = {
        configId: configId,
        cznId: this.$regionAndDepartmentInfo.department.id,
        startDate: getPeriodStartDate(this.period),
        endDate: getPeriodEndDate(this.period)
      }
      const req = await getAnketsCount(anketQuerySpec)
      if (req.ok) {
        return req.payload
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: loadAnketConfigs " + req.error;
      }
    },

  },
  async beforeMount() {
    await this.initialize()
    this.apiLoaded = true;
  }
}
</script>

<style scoped lang="scss">
h1 {
  display: flex;
  align-items: center;

  .badge {
    width: 152px;
    height: 60px;
    background: #E8E8E9;
    border: 1px solid #E8E8E9;
    border-radius: 4px;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 70px;
    color: $cblack;
  }

  .v-progress-circular {
    margin-left: 20px;
  }

  .v-btn {
    margin-left: auto;
  }
}

.manual-link {
  margin: 20px 0;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-bottom: 20px;

    .v-icon {
      color: #0033a0;
      margin-right: 10px;
    }
  }
}

.list {
  table {
    margin-bottom: 25px;

    th {
      border-bottom: 1px solid #E8E8E9;
    }

    td:nth-child(2),
    th:nth-child(2) {
      text-align: right;
    }

    td > div {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;

      svg {
        margin-right: 8px;
        flex-shrink: 0;
        margin-top: 5px;
      }
    }

    tr:last-child td {
      border-bottom: 1px solid #E8E8E9;
    }
  }
}

.push-right {
  float: right;
  margin-top: 15px;
}
</style>

<style lang="scss">
@import "../../styles/main";
</style>

<style>
.v-progress-circular__info {
  font-size: 13px !important;
  text-align: center;
  width: 100%;
}
</style>
